import { useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Lock,
  ScrollText,
  Activity,
  Flame,
  ArrowRightLeft,
  Coins,
  LucideIcon,
  ArrowRight,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

import { CenteredRowCell } from "@/components/CenteredRowCell";
import { CommonRow } from "@/components/CommonRow";
import { ScrollArea } from "@/components/ui/scroll-area";
import { CopyableText } from "./CopyableText";
import { truncateInMiddle, formatNumber } from "@/lib";

export type EventRowProps = {
  event: Record<any, any>;
  compact?: boolean;
  className?: string;
};

function createAssetTitle(
  assetEventType: string,
  unit: string,
  amount?: string
) {
  switch (assetEventType) {
    case "mint":
      return `Minted ${amount ?? 1} ${unit}`;
    case "burn":
      return `Burned ${amount ?? 1} ${unit}`;
    case "transfer":
      return `Transferred ${amount ?? 1} ${unit}`;
    default:
      return "Unknown Event";
  }
}

function FTAssetTitle({ event }: EventRowProps) {
  /*const [ftId, name] = event?.asset?.asset_id?.split("::") ?? [];
  const { ft, loading } = useFTMetadata({
    principal: ftId,
  });
  let amount = Number(event?.asset?.amount) ?? 1;
  if (!loading && !ft) {
    return (
      <div>
        {createAssetTitle(event?.asset?.asset_event_type ?? "unknown", name)}
      </div>
    );
  }
  const decimalDiv = Math.pow(10, ft?.decimals ?? 0);
  amount = Number(amount / decimalDiv);
  return (
    <div>
      {createAssetTitle(
        event?.asset?.asset_event_type ?? "unknown",
        ft?.symbol ?? name,
        `${formatNumber(amount)}`
      )}
    </div>
  );*/
  return <div />;
}

export function EventRow({ event, compact = false, className }: EventRowProps) {
  const icon: LucideIcon = useMemo(() => {
    switch (event.eventType) {
      case "Mint":
        return Coins;
      case "Burn":
        return Flame;
      case "Transfer":
        return ArrowRightLeft;
      default:
        return Activity;
    }
  }, [event]);
  const title = useMemo(() => {
    switch (event.eventType) {
      case "stx_asset":
        /*const amount = Number(asset?.amount ?? 0) / 1_000_000;
        return (
          <div>
            {createAssetTitle(
              asset?.asset_event_type ?? "unknown",
              "STX",
              `${formatNumber(amount)}`
            )}
          </div>
        );*/
        return <div />;
      case "stx_lock":
        const lock = event.stx_lock_event;
        const lockAmount = formatNumber(
          Number(lock?.locked_amount ?? 0) / 1_000_000
        );
        return `${formatNumber(lockAmount)} STX Locked`;
      case "unknown_mint":
        return <FTAssetTitle event={event} />;
      case "smart_contract_log":
        return "Smart Contract Log";
      default:
        return event.eventType ?? "Unknown Event Type";
    }
  }, [event]);
  const subtitle = useMemo(() => {
    const asset = event.asset;
    switch (event.event_type) {
      case "stx_lock":
        const lock = event.stx_lock_event;
        return `Unlocks at ${formatNumber(lock?.unlock_height)} height`;
      case "stx_asset":
      case "fungible_token_asset":
      case "non_fungible_token_asset":
        return (
          <div className="flex flex-row items-center gap-1 text-xs font-medium text-muted-foreground">
            <Button
              variant="link"
              className="h-auto w-auto text-xs p-0 font-medium text-muted-foreground"
              asChild
            >
              <Link to={`/accounts/${asset?.sender || ""}`}>
                {truncateInMiddle(asset?.sender || "", 10)}
              </Link>
            </Button>
            <ArrowRight className="h-3 w-3" />
            <Button
              variant="link"
              className="h-auto w-auto text-xs p-0 font-medium text-muted-foreground"
              asChild
            >
              <Link to={`/accounts/${asset?.recipient || ""}`}>
                {truncateInMiddle(asset?.recipient || "", 10)}
              </Link>
            </Button>
          </div>
        );
      case "smart_contract_log":
        return `Topic: ${event.contract_log?.topic}`;
      default:
        return undefined;
    }
  }, [event]);

  return (
    <CommonRow
      icon={icon}
      title={title}
      subtitle={subtitle}
      className={className}
    ></CommonRow>
  );
}
