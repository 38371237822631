import { Contract, IndexedBlock, IndexedTransaction } from "opscan-core";

const BASE_URL = "https://api.opscan.org/v1";

interface PaginationOptions {
  cursor?: number;
  limit?: number;
}

interface PaginatedResponse<T> {
  results: T[];
  hasNextPage: boolean;
  total: number;
  cursor?: string;
}

export default class OpnetApiClient {
  public static instance = new OpnetApiClient();

  async getBlockList(
    network: string,
    options?: PaginationOptions
  ): Promise<PaginatedResponse<IndexedBlock>> {
    const url = new URL(BASE_URL + "/" + network + "/blocks");
    if (options?.cursor !== undefined) {
      url.searchParams.append("cursor", options.cursor.toString());
    }
    if (options?.limit !== undefined) {
      url.searchParams.append("limit", options.limit.toString());
    }
    const response = await fetch(url.toString());
    if (!response.ok) {
      throw new Error("Failed to fetch block list");
    }
    return response.json();
  }

  async getBlockByHeight(
    network: string,
    height: bigint
  ): Promise<IndexedBlock | null> {
    const response = await fetch(
      `${BASE_URL}/${network}/blocks/${height.toString()}`
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch block by height: ${height}`);
    }
    return response.json();
  }

  async getBlockByHash(
    network: string,
    hash: string
  ): Promise<IndexedBlock | null> {
    const response = await fetch(
      `${BASE_URL}/${network}/blocks/${hash.toString()}`
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch block by hash: ${hash}`);
    }
    return response.json();
  }

  async getBlockTransactions(
    network: string,
    height: bigint,
    options?: PaginationOptions
  ): Promise<PaginatedResponse<IndexedTransaction>> {
    const url = new URL(
      `${BASE_URL}/${network}/blocks/${height.toString()}/transactions`
    );
    if (options?.cursor !== undefined) {
      url.searchParams.append("cursor", options.cursor.toString());
    }
    if (options?.limit !== undefined) {
      url.searchParams.append("limit", options.limit.toString());
    }
    const response = await fetch(url.toString());
    if (!response.ok) {
      throw new Error(`Failed to fetch transactions for block: ${height}`);
    }
    return response.json();
  }

  async getTransactionList(
    network: string,
    options?: PaginationOptions
  ): Promise<PaginatedResponse<IndexedTransaction>> {
    const url = new URL(`${BASE_URL}/${network}/transactions`);
    if (options?.cursor !== undefined) {
      url.searchParams.append("cursor", options.cursor.toString());
    }
    if (options?.limit !== undefined) {
      url.searchParams.append("limit", options.limit.toString());
    }
    const response = await fetch(url.toString());
    if (!response.ok) {
      throw new Error("Failed to fetch transaction list");
    }
    return response.json();
  }

  async getTransactionById(
    network: string,
    id: string
  ): Promise<IndexedTransaction | null> {
    const response = await fetch(`${BASE_URL}/${network}/transactions/${id}`);
    if (!response.ok) {
      throw new Error(`Failed to fetch transaction by id: ${id}`);
    }
    return response.json();
  }

  async getAverageGasFees(network: string) {
    const response = await fetch(
      `${BASE_URL}/${network}/transactions/avg-gas-fees`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch average gas fees");
    }
    return response.json();
  }

  async getWalletById(network: string, id: string): Promise<any> {
    const response = await fetch(`${BASE_URL}/${network}/wallets/${id}`);
    if (!response.ok) {
      throw new Error(`Failed to fetch wallet by id: ${id}`);
    }
    return response.json();
  }

  async getWalletTransactions(
    network: string,
    id: string,
    options?: PaginationOptions
  ): Promise<PaginatedResponse<IndexedTransaction>> {
    const url = new URL(`${BASE_URL}/${network}/wallets/${id}/transactions`);
    if (options?.cursor !== undefined) {
      url.searchParams.append("cursor", options.cursor.toString());
    }
    if (options?.limit !== undefined) {
      url.searchParams.append("limit", options.limit.toString());
    }
    const response = await fetch(url.toString());
    if (!response.ok) {
      throw new Error(`Failed to fetch transactions for wallet: ${id}`);
    }
    return response.json();
  }

  async getWalletBalances(
    network: string,
    id: string,
    options?: PaginationOptions
  ): Promise<any> {
    const url = new URL(`${BASE_URL}/${network}/wallets/${id}/balances`);
    if (options?.cursor !== undefined) {
      url.searchParams.append("cursor", options.cursor.toString());
    }
    if (options?.limit !== undefined) {
      url.searchParams.append("limit", options.limit.toString());
    }
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch balances for wallet: ${id}`);
    }
    return response.json();
  }

  async getWalletIncomingTransfers(
    network: string,
    id: string,
    options?: PaginationOptions
  ): Promise<any> {
    const url = new URL(`${BASE_URL}/${network}/wallets/${id}/transfers`);
    if (options?.cursor !== undefined) {
      url.searchParams.append("cursor", options.cursor.toString());
    }
    if (options?.limit !== undefined) {
      url.searchParams.append("limit", options.limit.toString());
    }
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch incoming transfers for wallet: ${id}`);
    }
    return response.json();
  }

  async getContractList(
    network: string,
    options?: PaginationOptions
  ): Promise<PaginatedResponse<Contract>> {
    const url = new URL(`${BASE_URL}/${network}/contracts`);
    if (options?.cursor !== undefined) {
      url.searchParams.append("cursor", options.cursor.toString());
    }
    if (options?.limit !== undefined) {
      url.searchParams.append("limit", options.limit.toString());
    }
    const response = await fetch(url.toString());
    if (!response.ok) {
      throw new Error("Failed to fetch contract list");
    }
    return response.json();
  }

  async getContractByAddress(
    network: string,
    id: string
  ): Promise<Contract | null> {
    const response = await fetch(`${BASE_URL}/${network}/contracts/${id}`);
    if (!response.ok) {
      throw new Error(`Failed to fetch contract by id: ${id}`);
    }
    return response.json();
  }

  async getTokenList(
    network: string,
    options?: PaginationOptions
  ): Promise<PaginatedResponse<Contract>> {
    const url = new URL(`${BASE_URL}/${network}/tokens`);
    if (options?.cursor !== undefined) {
      url.searchParams.append("cursor", options.cursor.toString());
    }
    if (options?.limit !== undefined) {
      url.searchParams.append("limit", options.limit.toString());
    }
    const response = await fetch(url.toString());
    if (!response.ok) {
      throw new Error("Failed to fetch token list");
    }
    return response.json();
  }

  async getTokenHoldersList(
    network: string,
    id: string,
    options?: PaginationOptions
  ): Promise<PaginatedResponse<any>> {
    const url = new URL(`${BASE_URL}/${network}/tokens/${id}/holders`);
    if (options?.cursor !== undefined) {
      url.searchParams.append("cursor", options.cursor.toString());
    }
    if (options?.limit !== undefined) {
      url.searchParams.append("limit", options.limit.toString());
    }
    const response = await fetch(url.toString());
    if (!response.ok) {
      throw new Error("Failed to fetch token holders list");
    }
    return response.json();
  }

  async getSearchList(
    network: string,
    id: string,
    options?: PaginationOptions
  ): Promise<PaginatedResponse<any>> {
    const url = new URL(`${BASE_URL}/${network}/search`);
    url.searchParams.append("id", id.toString());
    if (options?.cursor !== undefined) {
      url.searchParams.append("cursor", options.cursor.toString());
    }
    if (options?.limit !== undefined) {
      url.searchParams.append("limit", options.limit.toString());
    }
    const response = await fetch(url.toString());
    if (!response.ok) {
      throw new Error("Failed to fetch search results");
    }
    return response.json();
  }
}
