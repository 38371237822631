import { useMemo } from "react";
import {
  ArrowRight,
  FunctionSquare,
  Blocks,
  AlertTriangle,
} from "lucide-react";
import { Link, useParams } from "react-router-dom";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { useTransaction } from "@/hooks/useTransaction";
import { InfoCardCell } from "@/components/InfoCardCell";
import { PageContentHeader } from "@/components/PageContentHeader";
import { TransactionEventsCard } from "@/components/TransactionEventsCard";
import { BTCPriceConversion } from "@/components/BTCPriceConversion";
import {
  formatDateToNow,
  truncateInMiddle,
  createTitleForTransaction,
  formatNumber,
  gasToSats,
  satsToBTC,
} from "@/lib";
import { Separator } from "@/components/ui/separator";
import { CopyableText } from "@/components/CopyableText";
import { useBlock } from "@/hooks";

// todo: cleanup
function FunctionCard({ transaction }: { transaction: any }) {
  const decodedMethodCall = useMemo(() => {
    const decodedMethod = transaction?.decodedMethodArgs ?? {};
    return {
      name: decodedMethod?.name,
      args: Object.entries(decodedMethod?.props ?? {}),
    };
  }, [transaction]);
  return (
    <Card className="overflow-hidden">
      <CardHeader>
        <CardTitle>
          <div className="flex items-center gap-2 justify-between">
            <div className="flex gap-2 items-center">
              <FunctionSquare className="h-5 w-5" />
              {decodedMethodCall?.name}
            </div>
          </div>
        </CardTitle>
      </CardHeader>
      {decodedMethodCall?.args?.length > 0 && (
        <CardContent className="p-5 pt-0 flex flex-col gap-4">
          {decodedMethodCall.args.map((arg: any, i: number) => (
            <div className="flex flex-col border rounded-xl" key={i}>
              <div className="flex flex-row grow items-center justify-between gap-4 p-4">
                <div className="text-sm font-bold w-40 text-ellipsis">
                  {arg[0].toUpperCase()}
                </div>
              </div>
              <Separator />
              <div className="text-sm p-4 text-wrap break-words">{arg[1]}</div>
            </div>
          ))}
        </CardContent>
      )}
    </Card>
  );
}

function InputsCard({ transaction }: { transaction: any }) {
  return (
    <Card className="overflow-hidden grow">
      <CardHeader>
        <CardTitle>
          <div className="flex items-center gap-2 justify-between">
            <div className="flex gap-2 items-center">Inputs</div>
          </div>
        </CardTitle>
      </CardHeader>
      {transaction?.inputs.length > 0 && (
        <CardContent className="p-5 pt-0 flex flex-col gap-4">
          {transaction.inputs.map((input: any, i: number) => (
            <div className="flex flex-col border rounded-xl" key={i}>
              <div className="flex flex-row grow items-center justify-between gap-4 p-4">
                <div className="text-sm font-bold w-40 text-ellipsis">
                  <CopyableText
                    text={input.address}
                    displayText={
                      input.address && truncateInMiddle(input.address, 16)
                    }
                  />
                </div>
                <div className="text-sm font-bold text-ellipsis">
                  {satsToBTC(BigInt(input.value))} BTC
                </div>
              </div>
              <Separator />
              <div className="text-sm p-4 text-wrap break-words flex flex-col gap-2">
                <InfoCardCell
                  title="TX ID"
                  value={
                    <CopyableText
                      text={input.originalTransactionId}
                      displayText={
                        input.originalTransactionId &&
                        truncateInMiddle(input.originalTransactionId, 16)
                      }
                    />
                  }
                />
                <InfoCardCell
                  title="TX OUTPUT INDEX"
                  value={
                    <CopyableText
                      text={input.outputTransactionIndex}
                      displayText={input.outputTransactionIndex}
                    />
                  }
                />
              </div>
            </div>
          ))}
        </CardContent>
      )}
    </Card>
  );
}

function OutputsCard({ transaction }: { transaction: any }) {
  return (
    <Card className="overflow-hidden grow">
      <CardHeader>
        <CardTitle>
          <div className="flex items-center gap-2 justify-between">
            <div className="flex gap-2 items-center">Outputs</div>
          </div>
        </CardTitle>
      </CardHeader>
      {transaction?.outputs.length > 0 && (
        <CardContent className="p-5 pt-0 flex flex-col gap-4">
          {transaction.outputs.map((output: any, i: number) => (
            <div className="flex flex-col border rounded-xl" key={i}>
              <div className="flex flex-row grow items-center justify-between gap-4 p-4">
                <div className="text-sm font-bold w-40 text-ellipsis">
                  {output.scriptPubKey?.address ? (
                    <CopyableText
                      text={output.scriptPubKey?.address}
                      displayText={
                        output.scriptPubKey?.address &&
                        truncateInMiddle(output.scriptPubKey?.address, 16)
                      }
                    />
                  ) : (
                    "No Address"
                  )}
                </div>
                <div className="text-sm font-bold text-ellipsis">
                  {satsToBTC(BigInt(output.value))} BTC
                </div>
              </div>
            </div>
          ))}
        </CardContent>
      )}
    </Card>
  );
}

function BlockCard({ height }: { height: string }) {
  const { block, loading } = useBlock({ heightOrHash: `${height}` });
  return (
    <Card className="flex flex-col overflow-hidden lg:w-1/3">
      <CardHeader>
        <CardTitle className="flex justify-between items-center">
          Block
          <Blocks className="text-[#FF7900] h-5 w-5" />
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-2 grow">
        <InfoCardCell
          title="HEIGHT"
          value={
            <CopyableText
              text={height}
              displayText={`#${Number(height)?.toLocaleString("en-US")}`}
            />
          }
        />
        <InfoCardCell
          title="HASH"
          value={
            <CopyableText
              text={block?.hash}
              displayText={block && truncateInMiddle(block?.hash, 16)}
            />
          }
        />
      </CardContent>
      <CardFooter className="p-0">
        <Button className="flex-1 rounded-none uppercase bg-[#FF7900]" asChild>
          <Link to={`/blocks/${height}`}>
            View Block <ArrowRight className="ml-1 h-5 w-5" />
          </Link>
        </Button>
      </CardFooter>
    </Card>
  );
}

export function TransactionPage() {
  const { id } = useParams();
  const { transaction } = useTransaction({
    hash: id ?? "",
  });
  const type: string = useMemo(() => {
    switch (transaction?.type) {
      case "interaction":
        return "Contract Call";
      case "coinbase":
        return "Coinbase";
      case "deployment":
        return "Contract Deploy";
      case "generic":
        return "Transfer";
      case "wrap":
        return "Wrap";
      case "unwrap":
        return "Unwrap";
      default:
        return "Unknown Transaction Type";
    }
  }, [transaction]);
  const inputValue = satsToBTC(BigInt(transaction?.inputValue ?? 0));
  const outputValue = satsToBTC(BigInt(transaction?.outputValue ?? 0));
  const burnedAmount = satsToBTC(BigInt(transaction?.burnedBitcoin ?? 0));
  const gasAmount = satsToBTC(gasToSats(BigInt(transaction?.gasUsed ?? 0)));

  return (
    <div className="flex container p-4 space-y-4 flex-col">
      <PageContentHeader
        pre={
          <>
            {transaction?.revert ? (
              <Badge className="bg-destructive">Reverted</Badge>
            ) : (
              <Badge>Success</Badge>
            )}
            <Badge>{type}</Badge>
          </>
        }
        title={transaction && createTitleForTransaction(transaction)}
        accessory={transaction && formatDateToNow(transaction?.blockTime)}
      />
      <div className="flex flex-col grow gap-4 justify-center">
        {transaction?.revert && (
          <Card className="flex flex-col overflow-hidden grow bg-destructive">
            <CardContent className="flex flex-row items-center gap-2 text-white p-4">
              <AlertTriangle className="h-4 w-4" />
              {transaction?.revert}
            </CardContent>
          </Card>
        )}
        <div className="flex flex-col lg:flex-row gap-4">
          <Card className="flex flex-col overflow-hidden grow">
            <CardHeader>
              <CardTitle>Summary</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col gap-2 grow">
              <InfoCardCell
                title="ID"
                value={
                  <CopyableText
                    text={transaction?.id}
                    displayText={transaction?.id}
                  />
                }
              />
              <InfoCardCell
                title="HASH"
                value={
                  <CopyableText
                    text={transaction?.hash}
                    displayText={transaction?.hash}
                  />
                }
              />
              <InfoCardCell
                title="INPUT VALUE"
                value={
                  <>
                    {`${formatNumber(inputValue)} BTC `}
                    <BTCPriceConversion
                      amount={inputValue}
                      className="text-xs font-medium text-muted-foreground"
                    />
                  </>
                }
              />
              <InfoCardCell
                title="OUTPUT VALUE"
                value={
                  <>
                    {`${formatNumber(outputValue)} BTC `}
                    <BTCPriceConversion
                      amount={outputValue}
                      className="text-xs font-medium text-muted-foreground"
                    />
                  </>
                }
              />
              {(transaction?.vaultAddress ||
                transaction?.unwrapVaultAddress) && (
                <InfoCardCell
                  title="VAULT"
                  value={
                    <CopyableText
                      text={
                        transaction?.vaultAddress ||
                        transaction?.unwrapVaultAddress
                      }
                      displayText={
                        transaction?.vaultAddress ||
                        transaction?.unwrapVaultAddress
                      }
                      href={`/accounts/${transaction?.vaultAddress || transaction?.unwrapVaultAddress}`}
                    />
                  }
                />
              )}
              {transaction?.contractAddress && (
                <InfoCardCell
                  title="CONTRACT"
                  value={
                    <CopyableText
                      text={transaction?.contractAddress}
                      displayText={transaction?.contractAddress}
                      href={`/accounts/${transaction?.contractAddress}`}
                    />
                  }
                />
              )}
              {transaction?.type === "deployment" && (
                <InfoCardCell
                  title="DEPLOYER"
                  value={
                    <CopyableText
                      text={transaction?.deployerAddress}
                      displayText={transaction?.deployerAddress}
                      href={`/accounts/${transaction?.deployerAddress}`}
                    />
                  }
                />
              )}
              {(transaction?.type === "interaction" ||
                transaction?.type === "wrap" ||
                transaction?.type === "unwrap") && (
                <InfoCardCell
                  title="CALLER"
                  value={
                    <CopyableText
                      text={transaction?.caller}
                      displayText={transaction?.caller}
                      href={`/accounts/${transaction?.caller}`}
                    />
                  }
                />
              )}
              {transaction?.type === "generic" &&
                transaction?.inputAddresses?.length === 1 && (
                  <InfoCardCell
                    title="SENDER"
                    value={
                      <CopyableText
                        text={transaction?.inputAddresses[0]}
                        displayText={transaction?.inputAddresses[0]}
                        href={`/accounts/${transaction?.inputAddresses[0]}`}
                      />
                    }
                  />
                )}
              <InfoCardCell
                title="BLOCK INDEX"
                value={transaction?.index?.toLocaleString("en-US")}
              />
              {transaction?.type !== "generic" &&
                transaction?.type !== "coinbase" && (
                  <InfoCardCell
                    title="GAS USED"
                    value={
                      <>
                        {`${Number(gasAmount)} BTC `}
                        <BTCPriceConversion
                          amount={gasAmount}
                          className="text-xs font-medium text-muted-foreground"
                        />
                      </>
                    }
                  />
                )}
              {transaction?.type === "wrap" && (
                <InfoCardCell
                  title="WRAP FEES"
                  value={
                    <>
                      {`${satsToBTC(BigInt(transaction?.wrappingFees ?? 0))} BTC `}
                      <BTCPriceConversion
                        amount={satsToBTC(
                          BigInt(transaction?.wrappingFees ?? 0)
                        )}
                        className="text-xs font-medium text-muted-foreground"
                      />
                    </>
                  }
                />
              )}
              <InfoCardCell
                title="BURNED"
                value={
                  <>
                    {`${Number(burnedAmount)} BTC `}
                    <BTCPriceConversion
                      amount={burnedAmount}
                      className="text-xs font-medium text-muted-foreground"
                    />
                  </>
                }
              />
            </CardContent>
            {(transaction?.type === "deployment" ||
              transaction?.type === "interaction") && (
              <CardFooter className="p-0">
                <Button className="flex-1 rounded-none uppercase" asChild>
                  <Link to={`/accounts/${transaction?.contractAddress}`}>
                    View Contract <ArrowRight className="ml-1 h-5 w-5" />
                  </Link>
                </Button>
              </CardFooter>
            )}
          </Card>
          {transaction && <BlockCard height={transaction?.blockHeight} />}
        </div>
        {transaction?.events?.length > 0 && (
          <TransactionEventsCard transaction={transaction} />
        )}
        {transaction?.type === "interaction" &&
          transaction?.decodedMethodArgs !== "{}" && (
            <FunctionCard transaction={transaction} />
          )}
        <div className="flex flex-col lg:flex-row gap-4">
          {transaction?.inputs.length > 0 && (
            <InputsCard transaction={transaction} />
          )}
          {transaction?.outputs.length > 0 && (
            <OutputsCard transaction={transaction} />
          )}
        </div>
      </div>
    </div>
  );
}
