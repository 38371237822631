import { useEffect, useRef, useState } from "react";

import * as monaco from "monaco-editor";
import clarityLang from "../monaco-langs/ClarityLang";

import { Toggle } from "@/components/ui/toggle";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

monaco.languages.register({ id: "clarity" });
monaco.languages.setMonarchTokensProvider("clarity", clarityLang as any);

const CodeEditor = ({ language, code, schema }: any) => {
  const [lastEditor, setLastEditor] = useState<any>(null);
  const editorRef = useRef(null);

  useEffect(() => {
    if (editorRef.current) {
      lastEditor?.dispose();

      const editor = monaco.editor.create(editorRef.current, {
        value: code,
        language: language,
        theme: "vs-dark",
        readOnly: true,
        automaticLayout: true,
        codeLens: false,
        minimap: { enabled: false },
        stickyScroll: {
          enabled: false,
        },
        fontSize: 14,
      });

      setLastEditor(editor);

      // Auto format the code on load
      editor.getAction("editor.action.formatDocument")?.run();

      return () => {
        editor.dispose();
      };
    }
  }, [language, code]);

  return (
    <div
      ref={editorRef}
      className="overflow-hidden rounded-lg touch-none"
      style={{ width: "100%", height: "400px" }}
      key="edt"
    />
  );
};

export type SourceCodeProps = {
  abi?: string;
  clarity: string;
};

export function SourceCodeCard({ clarity, abi }: SourceCodeProps) {
  const [showABI, setShowABI] = useState(false);
  return (
    <Card className="overflow-hidden w-full">
      <CardHeader>
        <CardTitle className="flex justify-between">
          Bytecode
          <Toggle
            aria-label="Toggle bold"
            variant="outline"
            className="text-xs px-2 h-8"
            onClick={() => setShowABI(!showABI)}
          >
            {showABI ? "View Code" : "View ABI"}
          </Toggle>
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-2 grow">
        <CodeEditor
          language={showABI ? "json" : "clarity"}
          code={showABI ? abi : clarity}
        />
      </CardContent>
    </Card>
  );
}
